window.addEventListener('load', () => {
  function initAnimation(){
    let canvas = document.getElementById('canvas');
    let ctx = canvas.getContext('2d');

    let imagePath = ('/assy/images/main-image.jpg');
    if (window.matchMedia('(max-width: 480px)').matches) {
      imagePath = ('/assy/images/main-image_smart.jpg');
    }
    let image = new Image();
    image.src = imagePath;

    //set canvas width and height
    canvas.width = 1920;
    canvas.height = 1080;
    if (window.matchMedia('(max-width: 480px)').matches) {
      canvas.height = 375;
      canvas.width = Number(window.innerWidth);
      // canvas.height = Number(canvas.width * .489);
    }
    image.onload = function(){
      initDraw();
      loop();
    }

    let canvasEndX = canvas.width;
    let canvasEndY = canvas.height;
    let waveStartPoint = canvasEndY-150;

    let amplitude = 60;
    if (window.matchMedia('(max-width: 480px)').matches) {
      amplitude = 40;
    }
    let period = 1200;
    let degree = 0;

    function initDraw(){
      imageSet(image,canvasEndX,canvasEndY);
      waveDrawing(waveStartPoint,canvasEndX,canvasEndY,degree,amplitude,period);
    }

    function loop(){
      setInterval(function(){
        imageSet(image,canvasEndX,canvasEndY);
        waveDrawing(waveStartPoint,canvasEndX,canvasEndY,degree,amplitude,period);
        degree += 2;
      },30)
    }

    function imageSet(imageObj,canvasEndX,canvasEndY){
      let imgWidth = imageObj.width;
      let imgHeight = imageObj.height;
      ctx.globalCompositeOperation = "destination-over";
      ctx.drawImage(image,0,0,imgWidth,imgHeight,0,0,canvasEndX,canvasEndY);
    }

    function waveDrawing(waveStartPoint,canvasEndX,canvasEndY,deg,am,tp){
      let waveStartY = waveStartPoint;
      ctx.globalCompositeOperation = "destination-out";
      ctx.beginPath();
      ctx.moveTo(0, waveStartY);

      for (let x=0; x <= canvasEndX; x+= 1) {
        let y = -am*Math.sin((Math.PI/tp)*(deg+x));
        ctx.lineTo(x, y+waveStartY);
      }

      ctx.lineTo(canvasEndX,canvasEndY);
      ctx.lineTo(0,canvasEndY);
      ctx.closePath();

      ctx.fillStyle = "rgba(255,255,255,1)"; //opacity 1
      ctx.fill();
    }
  } // end initAnimation()
  initAnimation()


  function initAnimationMid(){
    let canvas = document.getElementById('canvas-room');
    let ctx = canvas.getContext('2d');

    let imagePath = ('/assy/images/middle-image.jpg');
    if (window.matchMedia('(max-width: 480px)').matches) {
      imagePath = ('/assy/images/middle-image_smart.jpg');
    }
    let image = new Image();
    image.src = imagePath;

    //set canvas width and height
    canvas.width = 1920;
    canvas.height = 1080;
    if (window.matchMedia('(max-width: 480px)').matches) {
      canvas.height = 375;
      canvas.width = Number(window.innerWidth);
      // canvas.height = Number(canvas.width * .489);
    }
    image.onload = function(){
      initDraw();
      loop();
    }

    let canvasEndX = canvas.width;
    let canvasEndY = canvas.height;
    let waveStartPoint = canvasEndY-150;

    let amplitude = 60;
    if (window.matchMedia('(max-width: 480px)').matches) {
      amplitude = 40;
    }
    let period = 1200;
    let degree = 0;

    function initDraw(){
      imageSet(image,canvasEndX,canvasEndY);
      waveDrawing(waveStartPoint,canvasEndX,canvasEndY,degree,amplitude,period);
    }

    function loop(){
      setInterval(function(){
        imageSet(image,canvasEndX,canvasEndY);
        waveDrawing(waveStartPoint,canvasEndX,canvasEndY,degree,amplitude,period);
        degree += 2;
      },30)
    }

    function imageSet(imageObj,canvasEndX,canvasEndY){
      let imgWidth = imageObj.width;
      let imgHeight = imageObj.height;
      ctx.globalCompositeOperation = "destination-over";
      ctx.drawImage(image,0,0,imgWidth,imgHeight,0,0,canvasEndX,canvasEndY);
    }

    function waveDrawing(waveStartPoint,canvasEndX,canvasEndY,deg,am,tp){
      let waveStartY = waveStartPoint;
      ctx.globalCompositeOperation = "destination-out";
      ctx.beginPath();
      ctx.moveTo(0, waveStartY);

      for (let x=0; x <= canvasEndX; x+= 1) {
        let y = -am*Math.sin((Math.PI/tp)*(deg+x));
        ctx.lineTo(x, y+waveStartY);
      }

      ctx.lineTo(canvasEndX,canvasEndY);
      ctx.lineTo(0,canvasEndY);
      ctx.closePath();

      ctx.fillStyle = "rgba(255,255,255,1)"; //opacity 1
      ctx.fill();
    }
  } // end initAnimationMid()
  initAnimationMid()
  //canvas-room
  // END CANVAS ANIMATION.

  // calc main-visual-wrap

  let windowHeight = window.innerHeight
  let main_visual = document.getElementsByClassName('main-visual-wrap')[0]
  let calcPosition = (Math.round(window.innerHeight * .75)) - window.innerHeight

  if (window.matchMedia('(min-width: 960px)').matches) {
    // document.getElementById('canvas').style.transform = 'translate(-50%, ' + calcPosition + 'px)'
    document.getElementsByClassName('main-visual-wrap')[0].style.marginTop = calcPosition + 'px'
  }

}) // window onload
